<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-4>
      <v-flex>
        <v-tabs
          id="mycustomtab"
          v-model="tabValue"
          centered
          slider-color="yellow"
          fixed-tabs
        >
          <v-tab style="color: #68d389"> VOLUNTEERING </v-tab>
          <v-tab style="color: #68d389">
            CORPORATE SOCIAL RESPONSIBILITY (CSR) PARTNERS
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabValue">
          <v-tab-item>
            <GetVolunteering
              :volunteering="volunteering"
              :pages="pages"
              @stepper="winStepper"
            ></GetVolunteering>
          </v-tab-item>
          <v-tab-item>
            <GetCorporate
              :corporate="corporate"
              :pages="pages"
              @stepper="winStepper"
            ></GetCorporate>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import GetCorporate from "./getCorporate";
import GetVolunteering from "./getVolunteering";
export default {
  components: {
    GetCorporate,
    GetVolunteering,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      currentPage: 1,
      pages: 0,
      count: 20,
      categories: [],
      volunteering: [],
      corporate: [],
      tabValue: 0,
      titleArray: [
        { title: "Volunteering", value: "volunteering" },
        {
          title: "CORPORATE SOCIAL RESPONSIBILITY (CSR) PARTNERS",
          value: "corporate",
        },
      ],
    };
  },
  beforeMount() {
    this.getVolunteering("volunteering");
    this.getCSR();
  },
  methods: {
    winStepper(window_data) {
      this.currentPage = window_data.currentPage;
      this.page = window_data.count;
    },
    getPrograms(val, catid) {
      this.appLoading = true;
      console.log(catid);
      axios({
        method: "GET",
        url: "/programme/getlist",
        params: { programmestatus: val, category: catid },
      })
        .then((response) => {
          this.appLoading = false;
          this.programs = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getVolunteering() {
      this.appLoading = true;
      axios({
        url: "/dynamicdata/volunteering/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.volunteering = response.data.data;
          this.pages = response.data.pages;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCSR() {
      this.appLoading = true;
      axios({
        url: "/dynamicdata/corporate/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.corporate = response.data.data;
          this.pages = response.data.pages;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
#mycustomtab .v-tab {
  max-width: 100% !important;
}
</style>